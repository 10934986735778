// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import handorgel from "handorgel";

const PARAMS     = new URLSearchParams(window.location.search);
const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    const HANDORGEL = new handorgel(element);

    if (PARAMS.get("highlight")) {
        HANDORGEL.folds.forEach((fold) => {
            if (fold.content.querySelector("mark")) {
                fold.open();
            }
        });
    }
});
